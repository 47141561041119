import pic1 from "../assets/pic/roller/L/plasticB.JPG";
import pic2 from "../assets/pic/roller/M/satin/satinB.png";
import pic3 from "../assets/pic/roller/S/5/spare/spareA.JPG";
import pic4 from "../assets/pic/roller/S/10/spare/spareA.JPG";
import pic5 from "../assets/pic/roller/S/20/sB.JPG";
import pic6 from "../assets/pic/roller/S/25/sA.JPG";
import pic7 from "../assets/pic/roller/S/5/sA.png";
import pic8 from "../assets/pic/roller/S/10/sA.png";
import pic9 from "../assets/pic/roller/S/15/sA.JPG";
import pic10 from "../assets/pic/roller/S/20/sA.JPG";
import pic11 from "../assets/pic/roller/L/plasticA.JPG";
import pic12 from "../assets/pic/roller/L/satinB.JPG";
import pic13 from "../assets/pic/roller/M/plastic/plasticA.JPG";
import pic14 from "../assets/pic/roller/M/satin/satinA.JPG";
import pic15 from "../assets/pic/paint brush/1/1a.jpg";
import pic16 from "../assets/pic/paint brush/2/2e.jpg";
import pic17 from "../assets/pic/paint brush/nim/h.jpg";
import pic18 from "../assets/pic/paint brush/2nim/2nf.jpg";
import pic19 from "../assets/pic/paint brush/3/3c.jpg";
import pic20 from "../assets/pic/paint brush/4/4a.jpg";
import pic21 from "../assets/pic/maq/m2.jpg";
export const product = [
  {
    id:1,
    title: "test7",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic5,
    name:"rollerSmall",
    url:"/smallRoller"
  },
//   {
//     id:2,
//     title: "test2",
//     numberInStock: 6,
//     dailyRentalRate: 2.5,
//     image:pic8,
//     name:"یدک غلطک انگشتی",
//     url:"/smallSpareRoller"
// //
//   },
  {
    id: 3,
    title: "test8",
    numberInStock: 5,
    dailyRentalRate: 2.5,
    image:pic2,
    name:"rollerMedium",
    url:"/mediumRoller"

  },
  // {
  //   id: 4,
  //   title: "test9",
  //   numberInStock: 6,
  //   dailyRentalRate: 2.5,
  //   image:pic9,
  //   name:"یدک غلطک نیمه قطور ",
  //   url:"/mediumSpareRoller"

  // },
  {
    id: 5,
    title: "test10",
    numberInStock: 8,
    dailyRentalRate: 3.5,
    image:pic1,
    name:"rollerLarge",
    url:"/largeRoller"
  },
  {
    id:6,
    title: "test1",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic11,
    name:"rollerSmall5cm",
    url:"/rollerSmall"

  },
  {
    id: 7,
    title: "test2",
    numberInStock: 5,
    dailyRentalRate: 2.5,
    image:pic12,
    name:"rollerSmall10cm",
    url:"/rollerSmall"

  },
  {
    id:8,
    title: "test2",
    numberInStock: 5,
    dailyRentalRate: 2.5,
    image:pic12,
    name:"rollerSmall15cm",
    url:"/rollerSmall"

  },  {
    id: 9,
    title: "test2",
    numberInStock: 5,
    dailyRentalRate: 2.5,
    image:pic12,
    name:"rollerSmall20cm",
    url:"/rollerSmall"

  },
  {
    id: "10",
    title: "test5",
    numberInStock: 5,
    dailyRentalRate: 2.5,
    image:pic12,
    name:"rollerSmall25cm"

  },
  {
    id: "11",
    title: "test6",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic11,
    name:"oilrollerMedium",

  },
  {
    id: "12",
    title: "test6",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic11,
    name:"plasticrollerMedium",

  },
  {
    id: "13",
    title: "test6",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic11,
    name:"oilrollerLarge",

  },
  {
    id: "14",
    title: "test6",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic11,
    name:"plasticrollerLarge",

  },
  {
    id: "15",
    title: "test13",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic5,
    name:"oilpaintBrush"

  },
  {
    id: "16",
    title: "test14",
    numberInStock: 5,
    dailyRentalRate: 2.5,
    image:pic21,
    name:"maqpaintBrush",
    url:"/brushMaq"

  },
  {
    id: "17",
    title: "test13",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic21,
    name:"maqpaintBrushAzin",
    url:"/azinBrush"
  },
  {
    id: "18",
    title: "test14",
    numberInStock: 5,
    dailyRentalRate: 2.5,
    image:pic21,
    name:"maqpaintBrushParyab",
    url:"/paryabBrush"
  },
  {
    id: "19",
    title: "test13",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic21,
    name:"maqpaintBrushAzin12"
  },
  {
    id: "20",
    title: "test14",
    numberInStock: 5,
    dailyRentalRate: 2.5,
    image:pic21,
    name:"maqpaintBrushAzin14"

  },
  {
    id: "21",
    title: "test17",
    numberInStock: 5,
    dailyRentalRate: 2.5,
    image:pic21,
    name:"maqpaintBrushAzin15"

  },
  {
    id: "22",
    title: "test18",
    numberInStock: 5,
    dailyRentalRate: 2.5,
    image:pic21,
    name:"maqpaintBrushAzinwhite14"

  },

  {
    id: "23",
    title: "test13",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic21,
    name:"maqpaintBrushParyab12"
  },
  {
    id: "24",
    title: "test14",
    numberInStock: 5,
    dailyRentalRate: 2.5,
    image:pic21,
    name:"maqpaintBrushParyab14"

  },
  {
    id: "25",
    title: "test17",
    numberInStock: 5,
    dailyRentalRate: 2.5,
    image:pic6,
    name:"maqpaintBrushParyab15"

  },
  {
    id: "26",
    title: "test18",
    numberInStock: 5,
    dailyRentalRate: 2.5,
    image:pic6,
    name:"maqpaintBrushParyab10"

  },
  {
    id: "27",
    title: "test13",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic5,
    name:"oilpaintBrush1"
  },
  {
    id: "28",
    title: "test13",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic5,
    name:"oilpaintBrush1/5"
  },  
  {
    id: "29",
    title: "test13",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic5,
    name:"oilpaintBrush2"
  },  
  {
    id: "30",
    title: "test13",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic5,
    name:"oilpaintBrush2/5"
  },  
  {
    id: "31",
    title: "test13",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic5,
    name:"oilpaintBrush3"
  },
  {
    id: "32",
    title: "test13",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic5,
    name:"oilpaintBrush4"
  },
]





// roller


export const paintRoller = [
  {
    id: 1,
    title: "test7",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic5,
    name:"rollerSmall",
    url:"/smallRoller"
  },
//   {
//     id: "2",
//     title: "test2",
//     numberInStock: 6,
//     dailyRentalRate: 2.5,
//     image:pic8,
//     name:"یدک غلطک انگشتی",
//     url:"/smallSpareRoller"
// //
//   },
  {
    id: 3,
    title: "test8",
    numberInStock: 5,
    dailyRentalRate: 2.5,
    image:pic2,
    name:"rollerMedium",
    url:"/mediumRoller"

  },
  // {
  //   id: "4",
  //   title: "test9",
  //   numberInStock: 6,
  //   dailyRentalRate: 2.5,
  //   image:pic9,
  //   name:"یدک غلطک نیمه قطور ",
  //   url:"/mediumSpareRoller"

  // },
  {
    id: 4,
    title: "test10",
    numberInStock: "8",
    dailyRentalRate: 3.5,
    image:pic1,
    name:"rollerLarge",
    url:"/largeRoller"
  },
 
]
export const smallRoller = [
  {
    id: "6",
    title: "test1",
    code:"S5",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic7,
    name:"rollerSmall5cm",
    url:"/rollerSmall",
    fabric:"پلی آمید",
    handleType:"پلاستیک"

  },
  // {
  //   id: "16",
  //   title: "test1",
  //   code:"S55",
  //   numberInStock: 6,
  //   dailyRentalRate: 2.5,
  //   image:pic3,
  //   name:"sparerollerSmall5cm",
  //   url:"/product/rollerSmall",
  //   fabric:"پلی آمید",
  //   handleType:"پلاستیک"

  // },
  {
    id: "7",
    title: "test2",
    code:"S10",
    numberInStock: 5,
    dailyRentalRate: 2.5,
    image:pic8,
    name:"rollerSmall10cm",
    url:"/rollerSmall",
    fabric:"پلی آمید",
    handleType:"پلاستیک"

  },
  // {
  //   id: "26",
  //   title: "test1",
  //   code:"S1010",
  //   numberInStock: 6,
  //   dailyRentalRate: 2.5,
  //   image:pic4,
  //   name:"sparerollerSmall10cm",
  //   url:"/product/rollerSmall",
  //   fabric:"پلی آمید",
  //   handleType:"پلاستیک"

  // },
  {
    id: "8",
    title: "test2",
    code:"S15",
    numberInStock: 5,
    dailyRentalRate: 2.5,
    image:pic9,
    name:"rollerSmall15cm",
    url:"/rollerSmall",
    fabric:"پلی آمید",
    handleType:"پلاستیک"


  },  
  {
    id: "9",
    title: "test2",
    code:"S20",
    numberInStock: 5,
    dailyRentalRate: 2.5,
    image:pic10,
    name:"rollerSmall20cm",
    url:"/rollerSmall",
    fabric:"پلی آمید",
    handleType:"پلاستیک"
  },
  {
    id: "10",
    title: "test5",
    code:"S25",
    numberInStock: 5,
    dailyRentalRate: 2.5,
    image:pic6,
    name:"rollerSmall25cm",
    fabric:"پلی آمید",
    handleType:"پلاستیک"


  },

]

export const mediumRoller = [
  {
    id: "11",
    title: "test6",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic14,
    name:"oilrollerMedium",
    fabric:"پلی آمید",
    handleType:"پلاستیک",
    code:"M25",
  },
  {
    id: "12",
    title: "test6",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic13,
    name:"plasticrollerMedium",
    fabric:"پلی آمید",
    handleType:"پلاستیک",
    code:"MA25",

  },
]
export const largeRoller = [
  {
    id: "13",
    title: "test6",
    code:"L25",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic12,
    name:"oilrollerLarge",
    fabric:"پلی آمید",
    handleType:"پلاستیک",

  },
  {
    id: "14",
    title: "test6",
    code:"LA25",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic11,
    name:"plasticrollerLarge",
    fabric:"پلی آمید",
    handleType:"پلاستیک",

  },
]


//brush

export const paintBrush = [
  {
    id: 15,
    title: "test13",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic18,
    name:"oilpaintBrush",
    url:"/oilyBrush"

  },
  {
    id: 16,
    title: "test14",
    numberInStock: 5,
    dailyRentalRate: 2.5,
    image:pic21,
    name:"maqpaintBrush",
    url:"/brushMaq"

  },

]
export const maqBrush = [
  {
    id: "17",
    title: "test13",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic21,
    name:"maqpaintBrushAzin",
    url:"/azinBrush"
  },
  {
    id: "18",
    title: "test14",
    numberInStock: 5,
    dailyRentalRate: 2.5,
    image:pic21,
    name:"maqpaintBrushParyab",
    url:"/paryabBrush"
  },

]
export const maqAzinBrush = [
  {
    id: "19",
    title: "test13",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic21,
    name:"maqpaintBrushAzin12"
  },
  {
    id: "20",
    title: "test14",
    numberInStock: 5,
    dailyRentalRate: 2.5,
    image:pic21,
    name:"maqpaintBrushAzin14"

  },
  {
    id: "21",
    title: "test17",
    numberInStock: 5,
    dailyRentalRate: 2.5,
    image:pic21,
    name:"maqpaintBrushAzin15"

  },
  {
    id: "22",
    title: "test18",
    numberInStock: 5,
    dailyRentalRate: 2.5,
    image:pic21,
    name:"maqpaintBrushAzinwhite14"

  },

]
export const maqParyabBrush = [
  {
    id: "23",
    title: "test13",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic21,
    name:"maqpaintBrushParyab12"
  },
  {
    id: "24",
    title: "test14",
    numberInStock: 5,
    dailyRentalRate: 2.5,
    image:pic21,
    name:"maqpaintBrushParyab14"

  },
  {
    id: "25",
    title: "test17",
    numberInStock: 5,
    dailyRentalRate: 2.5,
    image:pic21,
    name:"maqpaintBrushParyab15"

  },
  {
    id: "26",
    title: "test18",
    numberInStock: 5,
    dailyRentalRate: 2.5,
    image:pic21,
    name:"maqpaintBrushParyab10"

  },

]
export const oilyBrush = [
  {
    id: "27",
    title: "test13",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic15,
    name:"oilpaintBrush1",
    code:"SR10",
  },
  {
    id: "28",
    title: "test13",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic17,
    name:"oilpaintBrush1/5",
    code:"SR15",

  },  
  {
    id: "29",
    title: "test13",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic16,
    name:"oilpaintBrush2",
    code:"SR20",

  },  
  {
    id: "30",
    title: "test13",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic18,
    name:"oilpaintBrush2/5",
    code:"SR25",

  },  
  {
    id: "31",
    title: "test13",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic19,
    name:"oilpaintBrush3",
    code:"SR30",

  },
  {
    id: "32",
    title: "test13",
    numberInStock: 6,
    dailyRentalRate: 2.5,
    image:pic20,
    name:"oilpaintBrush4",
    code:"SR40",

  },
]

export function getProduct(id) {
  return product.find(m => m.id === id);
}