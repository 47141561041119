import React from "react";
import { NavLink } from "react-router-dom";
import "./footer.scss";

import almas from "../../assets/pic/almaswhite.png";
import { Text} from "../../language/language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone,faEnvelopeOpen,faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebook ,faLinkedin} from "@fortawesome/free-brands-svg-icons";


const Footer = () => {
  return (
    <>
    <div className="footer-header-pic">
        <div className="footer-header-txt">
            <Text tid="footerpoet"/>
        </div>
    </div>
    <footer className="footer-section">
        <div className="container">
          <div className="footer-cta pt-5 pb-5">
            <div className="footer-info">
                <div className="footer-info-sec">
                    <div className="single-cta">
                        <FontAwesomeIcon icon={faMapMarkerAlt} className="fontAws"/>
                        <div className="cta-text">
                            <h4>آدرس</h4>
                            <span>شهرک صنعتی خرم دشت</span>
                        </div>
                    </div>
                </div>
                <div className="footer-info-sec">
                    <div className="single-cta">
                        <FontAwesomeIcon icon={faPhone} className="fontAws"/>
                        <div className="cta-text">
                            <h4>شماره تماس</h4>
                            <div>09121448561</div>
                            <div>09127293928</div>
                        </div>
                    </div>
                </div>
                <div className="footer-info-sec">
                    <div className="single-cta">
                        <FontAwesomeIcon icon={faEnvelopeOpen} className="fontAws"/>
                        <div className="cta-text">
                            <h4>ایمیل</h4>
                            <span>almas.group.tools@gmail.com</span>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            {/* <div className="footer-content pt-5 pb-5">
                <div className=" footer-content-container">
                    <div className="col-xl-4 col-lg-4 mb-80">
                    <div className="footer-text">
                                <p>
مشتریان گرامی همچنین می توانند سایر تجهیزات مربوط به انواع رنگ آمیزی را به بهترین قیمت از طریق گروه تولیدی الماس به راحتی خریداری نمایند</p>
                            </div>
                            <div className="footer-social-icon">
                                <a href="!#"><i className="fab fa-facebook-f facebook-bg"></i></a>
                                <FontAwesomeIcon icon={faFacebook} className="fontAwsC"/>
                                <FontAwesomeIcon icon={faLinkedin} className="fontAwsC"/>
                                <FontAwesomeIcon icon={faEnvelopeOpen} className="fontAwsC"/>

                                <a href="!#"><i className="fab fa-twitter twitter-bg"></i></a>
                                <a href="!#"><i className="fab fa-google-plus-g google-bg"></i></a>
                            </div>
                           

                    </div> */}
                    {/* <div className="col-xl-4 col-lg-4 mb-60 d-flex justify-content-center"  >
                            <div className="footer-logo">
                                <a href="index.html"><img src={almas} className="img-fluid" alt="logo"/></a>
                            </div>
                            
                    </div>
         */}
                    {/* <div className="col-xl-4 col-lg-4 col-md-4 mb-40">
                        <div className="footer-widget">
                            <div className="footer-widget-heading">
                                <h3>منو</h3>
                            </div> */}
                        
                            {/* <ul className="footer-widget-heading-item">
                                <li><NavLink  activeClassName="selected" to="/">خانه</NavLink></li>
                                <li><NavLink  activeClassName="selected" to="/product">محصولات</NavLink></li>
                                <li><NavLink  activeClassName="selected" to="/contact-us">ارتباط با ما</NavLink></li>
                      
                            </ul> */}
                        {/* </div>
                    </div>
                </div>
            </div>
        </div> */}
        {/* <div className="copyright-area">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 text-center text-lg-left">
                        <div className="copyright-text">
                            <p>Copyright &copy; 2022 almas group</p>
                        </div>
                    </div>
                </div>
            </div>*/}
        </div> 
    </footer>
    </>
  );
};

export default Footer;
