import React from "react";


const NotFound=()=>{
    return(
        <div style={{display:"flex",justifyContent:"center",background: "lightblue",height: "100vh"}}>
            <div className="not-found">
              <img src="" alt="notfound"/>
              <p>صفحه ای یافت نشد</p>
            </div>
        </div>
    )
}

export default NotFound;