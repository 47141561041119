import React, { useContext } from "react";
import { NavLink,Link } from "react-router-dom";

import { Text, LanguageContext } from '../../language/language';
import LanguageSelector from '../../language/languageSelector';

import almas from "../../assets/pic/almaswhite.png";
import MobileMenu from "./mobilemenu";
import "./header.scss";

function Menu() {

  const { dictionary } = useContext(LanguageContext);

    return (
      <>
        <nav className="nav-menu">
          <div className="nav-container">
            <div className="nav-menu-list">
              <div id="mainListDiv" className="main_list">
                <ul className="navlinks">
                  <li>
                    <NavLink activeClassName="selected" to="/">
                    <Text tid="option1" />
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="selected" to="/product">
                    <Text tid="option2" />
                    </NavLink>
                  </li> 
                  <li>
                    <NavLink activeClassName="selected" to="/contact-us">
                    <Text tid="option3" />
                  
                    </NavLink>
                  </li>

                  {/* <li>
                    <LanguageSelector/>
                  </li> */}
                </ul>
              </div>
            </div>
            <MobileMenu />
            <div>
              <div className="navbar-title">
                <div className="navbar-txt"> 
                  <Link  to="/">
                    <Text tid="logo" />
                  </Link>
                  <div className="navbar-img">
                     <Link  to="/">
                       <img src={almas} alt="diamond" />
                     </Link>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </>
    );

}

export default Menu;
