import React, { Component } from "react";
import Footer from "../../component/footer/footer";
import {getProduct} from "../../services/productlist";
// import TextBox from "../../component/boxDescriptionDetail/BoxDescDetail";
class ProductDetails extends Component {
  state = {
    data:{
      id:"",
      title:"",
      numberInStock:"",
      dailyRentalRate:"",
      image:""
    }
  };
  productView = () => {};

  componentDidMount(){
    const productId=this.props.match.params.id;

    const product=getProduct(productId)
    if(!product) return this.props.history.replace('/not-found')

    this.setState({data:this.mapToviewModal(product)})
  }

  mapToviewModal(product){
    return{
      id:product.id,
      title:product.title,
      numberInStock:product.numberInStock,
      dailyRentalRate:product.dailyRentalRate,
      image:product.image
    }
  }





  render() {
    return (
      <>
        <div className="productDetail-Container">
        {/* <TextBox/> */}

            <div className="productDetail-desc">
              <div className="title1">مشخصات</div>
              <div>شماره قلم مو: {this.state.data.id}</div>
              <div>جنس دسته: {this.state.data.title}</div>
              <div>نوع مو: {this.state.data.title}</div>


            </div>
            <div className="details_img">
              <img src={this.state.data.image} alt="pic" />
                
            </div>
        </div>
        
        <Footer />
      </>
    );
  }
}

export default ProductDetails;
