import en from './en.json';
import fa from './fa.json';

export const dictionaryList = { fa, en };

export const languageOptions = {
  fa: 'فارسی',
  en: 'English',

};
