import React from 'react'
import "./header.scss";
import Menu from "./navbarMenu" ;

function Header(){
    return(
        <div className="navbar-container">
            <Menu  />
        </div>
    )
}

export default Header;
