import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignJustify, faTimes } from "@fortawesome/free-solid-svg-icons";
import almas from "../../assets/pic/72.png";
import { Text } from "../../language/language";
import LanguageSelector from "../../language/languageSelector";
import "./mobileMenu.scss";

function MobileMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const [link1, setLink1] = useState(false);
  const [link2, setLink2] = useState(false);
  const [width, setWidth] = useState("");

  function openMenu() {
    setIsOpen(!isOpen);
  }

  function closeMenu() {
    setIsOpen(false);
  }
  function showOptions() {
    setLink1(!link1);
  }
  function showOptions2() {
    setLink2(!link2);
  }
  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <div className="mobileMenuContainer">
      <FontAwesomeIcon
        icon={faAlignJustify}
        className="littleNav"
        style={{ color: "black", fontSize: "20px" }}
        onClick={openMenu}
      />
      {isOpen && width < 690 ? (
        <div
          className="modal-backdrop"
          onClick={() => {
            // close modal when outside of modal is clicked
            closeMenu();
          }}
        >
          <div
            className="mobileMenu"
            onClick={(e) => {
              // do not close modal if anything inside modal content is clicked
              e.stopPropagation();
            }}
          >
            <div className="closeContainer">
              <div className="d-flex align-items-center">
                <img src={almas} alt="diamond" />
                <span className="profileName">
                  <Text tid="logo" />
                </span>
              </div>
              <FontAwesomeIcon
                icon={faTimes}
                onClick={closeMenu}
                className="closeIcon"
              />
            </div>
            <div>
              <div onClick={showOptions} className="menuHeader">
                <Link to="/" onClick={closeMenu}>
                  <Text tid="option1" />
                </Link>
              </div>
            </div>
            <div>
              <div onClick={showOptions2} className="menuHeader">
                <Link to="/product" onClick={closeMenu}>
                  <Text tid="option2" />
                </Link>
              </div>
            </div>
            <div>
              <div onClick={showOptions2} className="menuHeader">
                <Link to="/contact-us" onClick={closeMenu}>
                  <Text tid="option3" />
                </Link>
              </div>
            </div>
            {/* <div>
              <div className="menuHeader">
                <LanguageSelector color="white" />
              </div>
            </div> */}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default MobileMenu;
