import React from "react";
import "./spinner.scss";

const Spinner = () => {
  
  const modalStyle = {
    width: "60%",
    minHeight: "200px",
    borderRadius: "10px",
  };
  return (
    <>
      <div className="spin-wrapper">
        <div className="spinner"></div>
      </div>
    </>
  );
};

export default Spinner;
