import React from "react";
import { Route, BrowserRouter as Router} from "react-router-dom";

import './App.scss';
import NotFound from "./pages/notFound";
import Main from "./pages/main";
import ScrollToTop from "./router/scrollToTop";
import { LanguageProvider } from './language/language';
//import ScrollToTopButton from "./component/scrollToTop/ScrollToTop";


function App() {
  return (
  <LanguageProvider>
    <ScrollToTop />
    <Router>
       <Route path="/" component={Main} />
       <Route path="*" element={<NotFound />}/>
    </Router>    
  </LanguageProvider>

  );
}

export default App;
