import React,{ Suspense} from 'react';

import { Route } from "react-router-dom";
import Spinner from "../component/spinner/spinner";
import Header from "../component/header/header";
// import Footer from "../component/footer/footer";
import ProductDetails from "./product/productDetails";
import NotFound from "./notFound";
import ScrollToTop from "../router/scrollToTop";

const Home=React.lazy(()=>import("./home/home"))
const Product=React.lazy(()=>import("./product/product"))
const PaintBrush=React.lazy(()=>import("./product/paintBrush"))
const PaintRoller=React.lazy(()=>import("./product/paintRoller"))
const RollerSmall=React.lazy(()=>import("./product/roller/rollerSmall"))
const RollerMedium=React.lazy(()=>import("./product/roller/rollerMedium"))
const RollerLarge=React.lazy(()=>import("./product/roller/rollerLarge"))
const MaqBrush=React.lazy(()=>import("./product/brush/maqlavis"))
const OilyBrush=React.lazy(()=>import("./product/brush/oily"))
const AzinBrush=React.lazy(()=>import("./product/brush/azin"))
const ParyabBrush=React.lazy(()=>import("./product/brush/paryab"))
const ContactUs=React.lazy(()=>import("./contactUs"))
const AboutUs=React.lazy(()=>import("./aboutUs"))

class Main extends React.Component{
    state={
        footer: {
            text:
              " - تمام حقوق این وب سایت متعلق به شرکت دانش بنیان فناوران ایمن شبکه (فاش) می باشد ",
          },
    }
    render(){
        return(
            <div className="mainbg">
                <Header />
                <div className="main-page">
                   <ScrollToTop/>
                    <Route path="/" exact
                    render={()=><Suspense
                    fallback={<div className="spinner-container"><Spinner /></div>}>
                    <Home/></Suspense>}
                    />
                   <Route path="/product" 
                    render={()=><Suspense
                    fallback={<div className="spinner-container"><Spinner /></div>}>
                    <Product/></Suspense>}
                    />
                    <Route path="/paint-brush" 
                    render={()=><Suspense
                    fallback={<div className="spinner-container"><Spinner /></div>}>
                    <PaintBrush/></Suspense>}
                    />
                    <Route path="/paint-roller" exact
                    render={()=><Suspense
                    fallback={<div className="spinner-container"><Spinner /></div>}>
                    <PaintRoller/></Suspense>}
                    />
                   <Route path="/smallRoller" 
                    render={()=><Suspense
                    fallback={<div className="spinner-container"><Spinner /></div>}>
                    <RollerSmall/></Suspense>}
                    />
                    <Route path="/mediumRoller" 
                    render={()=><Suspense
                    fallback={<div className="spinner-container"><Spinner /></div>}>
                    <RollerMedium/></Suspense>}
                    />
                    <Route path="/LargeRoller" 
                    render={()=><Suspense
                    fallback={<div className="spinner-container"><Spinner /></div>}>
                    <RollerLarge/></Suspense>}
                    />
                    <Route path="/brushMaq" 
                    render={()=><Suspense
                    fallback={<div className="spinner-container"><Spinner /></div>}>
                    <MaqBrush/></Suspense>}
                    />
                     <Route path="/oilyBrush" 
                    render={()=><Suspense
                    fallback={<div className="spinner-container"><Spinner /></div>}>
                    <OilyBrush/></Suspense>}
                    />
                    <Route path="/azinBrush" 
                    render={()=><Suspense
                    fallback={<div className="spinner-container"><Spinner /></div>}>
                    <AzinBrush/></Suspense>}
                    />
                    <Route path="/paryabBrush" 
                    render={()=><Suspense
                    fallback={<div className="spinner-container"><Spinner /></div>}>
                    <ParyabBrush/></Suspense>}
                    />
                    <Route path="/contact-us" 
                    render={()=><Suspense
                    fallback={<div className="spinner-container"><Spinner /></div>}>
                    <ContactUs/></Suspense>}
                    />
                    
                    <Route path="/about-us" 
                    render={()=><Suspense
                    fallback={<div className="spinner-container"><Spinner /></div>}>
                    <AboutUs/></Suspense>}
                    />
                    <Route path="/product/:id" component={ProductDetails} />
                    <Route path="/not-found" component={NotFound} />
           {/* <ScrollToTopButton /> */}
                </div> 
               
            </div>
    
        )

    }


}
export default Main;